.feat-card{

}    
.feat-card>img{
    max-width: 60px;
    margin-bottom: 2rem;
}
.feat-card>h3{
    font-size: 1.4rem;
}
.feat-card>p{
    margin-top: 0.2rem;
}