#earth-dot-wrapper{
    position: fixed;
    bottom: calc( 30% + env(safe-area-inset-bottom));
    left: 50%;
    transform: translate( -50%, 50%);
    z-index: 0;

    background: url("./earth-background-2.png");
    background-size: 68%;
    background-repeat: no-repeat;
    background-position: center;
    backdrop-filter: overlay;

    animation: backgroundRotation 2s ease-in-out infinite alternate;
}

@keyframes backgroundRotation {
    0%{ baopacity: 1;}
    50%{ opacity: 0.8;}
    100%{ opacity: 1;}
}