#stock-login{
    padding: min(4rem, 5vh);
    height: max-content;
    overflow: hidden;
}
#stock-login>h2{
    color: var(--title);
    text-align: center;
    margin: 2rem auto;
}

#close{
    position: absolute;
    top: 1rem;
    right: 1rem;
    aspect-ratio: 1/1;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

#stock-login>form{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto 1fr;
    align-items: center;
    gap: 2rem;
}
#stock-login>form>label{
    color: var(--text);
}
#stock-login>form>button{
    grid-column: 1/3;
    margin: 2rem auto;
    width: max-content;
    height: max-content;
    
}

#password-wrapper{
    position: relative;

}
#password-wrapper>input{
    width: 100%;
}
#password-wrapper>div{
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translate(0px, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    cursor: pointer;
}
#password-wrapper>div>img{
    height: 100%;
}

@media (max-width: 768px) {
    #stock-login{
        width: 95%;
    }
    #stock-login>form{
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto 1fr;
    }
    #stock-login>form>input{
        width: 100%;
    }
    #stock-login>form>button{
        grid-column: 1 / 2;
    }

}