.header-nav{
    grid-area: nav;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* justify-content: space-evenly; */
    border-radius:  10rem;
    gap: max(1rem, 10%);
    /* backdrop-filter: blur(5px); */
    /* box-shadow: 0px 0px 4px 0px var(--glow-color); */
    padding: 0.5rem;
    max-width: 30rem;
    min-width: 4rem;
    width: 100%;
    transition: all 0.8s;
}
.header-nav:hover{
    background-color: var(--glass-color);
    /* backdrop-filter: blur(30px); */
}

#menu{
    margin: auto;
}

.nav-item{
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 2px;
    border-bottom: 1px solid var(--de-text);
    color: var(--de-text);
    transition: all 0.2s, border-bottom 0.5s;
}
.nav-item:hover{
    color: var(--theme-hover);
    border-bottom: 1px solid var(--theme-hover);
}
.nav-text{
    margin: 0rem;
}