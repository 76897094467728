.finacial-products{
    width: 100%;
    background-color: var(--glow-color);
    border-top: 1px solid var(--backdrop-color);
    display: flex;
    justify-content: space-evenly;
    gap: 6px;
    transition: all 0.5s;
    padding: 0px 24px;
    border-bottom: 1px solid var(--shadow-color);
    overflow-y: hidden;
    height: 70px;
}

.product-container{
    /* border: 1px solid blue; */
    /* max-width: 180px; */
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 180px;
    max-height: 65px;
    position: relative;
}

.product{
    padding: 10px 20px;
    position: absolute;
    height: 65px;
    /* box-shadow: 0px 2px 8px 4px var(--shadow-color); */
    /* border-bottom: 4px solid var(--theme-hover);
    border-radius: 8px; */
    cursor: default;
    /* border-left: 1px solid var(--shadow-color);
    border-right: 1px solid var(--shadow-color); */
    display: grid;
    gap: 6px;
    grid-template-areas: 
        "name changePercent"
        "price change";
}
.product:hover{
    /* transform: rotateX(90deg); */
    transform: scale(1.03);
}
.product-name{
    grid-area: name;
    text-wrap: nowrap;
    font-size: 12px;
    width: 100%;
    justify-self: start;
    align-self: center;
    font-weight: 400;
    color: var(--de-text);
}
.product-price{
    grid-area: price;
    font-size: 18px;
    justify-self: start;
    align-self: center;
}
.product-change{
    grid-area: change;
    font-size: 12px;
    justify-self: center;
    align-self: center;
}
.product-change-percent{
    grid-area: changePercent;
    font-size: 12px;
    justify-self: center;
    align-self: center;
}