/* h1, h2, h3{
    text-shadow: 0px 0px 8px var(--theme-color);
} */
#platform-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: max-content;
    margin-top: min(10vh, 5rem);
}
#platform-name>img{
    width: auto;
    height: 15vw;
    min-height: 60px;
    max-height: 110px;
    aspect-ratio: 1/1;
    object-fit: contain;
}
#btns-sec{
    height: max-content;
    min-height: calc(100dvh - 10rem);
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4rem;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.btns-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
#features{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: min(2rem, 2vw);
    margin-top: 2rem;
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  */
}

#features>.card{
    box-shadow: none;
    background: none;
}

.card{
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: min(3rem, 6vw);
}
.card>img{
    width: 60%;
    margin-bottom: 2rem;
}

#key-sec{
    margin: 5rem auto;
}
#key-sec > p{
    width: 60%;
    min-width: min(100%, 400px);
}

#key-feat-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    min-width: 250px;
    flex-grow: 1;
}
.feat-card{
    align-self: stretch;
    max-width: none;
    box-shadow: none;
    border: 1px solid var(--border);
}

.feat-card>h3, .feat-card>p{
    text-align: center;
}

#video-wrapper{
    position: relative;
    height: 100%;
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: stretch;
    gap: 2rem;
}

#sticky-video-container{
    flex-grow: 1;
    width: 50%;
}

#futures-iframe{
    position: sticky;
    top: 10rem;
    width: 100%;
    /* min-width: 250px;
    width: 100%;
    max-width: 600px; */
    aspect-ratio: 1080/1800 !important;
}

iframe{
    border-style: none;
    border-radius: 10px;
}

video{
    top: 10rem;
    position: sticky;
    width: 100%;
    border-style: none;
    border-radius: 10px;
    border: 1px solid var(--text);
    box-shadow: 0px 2px 16px 8px var(--shadow-heavy-color);
}

.show-video{
    max-height: 600px;
    height: 90%;
    margin: 1rem;
    width: auto;
}

#go-btn{
    display: none;
}

/* #key-feat-grid > :nth-child(1){
    grid-column: 2/3;
}
#key-feat-grid > :nth-child(5){
    grid-column: 1/2;
} */

@media (max-width: 768px) {
    .btns-box{
        flex-direction: column;
    }
    .icon-btn>a>img, .icon-btn>div>img{
        width: 40%;       
    }

    .card>img{
        width: 40%;
    }
    #key-feat-grid{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    #go-btn{
        display: block;
    }
    .desktop-text{
        display: none;
    }
}